<template>
  <v-menu
    id="FeatureSelector"
    v-model="menu"
    :position-x="position.x"
    :position-y="position.y"
    absolute
    style="z-index: 2000"
    max-height="500"
  >
    <v-list>
      <v-list-item
        v-for="feature in featureList"
        dense
        @click="showFeature(feature)"
      >
        <v-list-item-avatar>
          <v-icon small>layers</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ getFeatureTitle(feature) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ getFeatureSubtitle(feature) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FeatureSelector',
  data: () => ({
    menu: false,
    position: { x: 0, y: 0 },
    featureList: [],
    multiselect: false
  }),
  methods: {
    open (featureList, position, multiselect = false) {
      this.position = { ...position }
      this.featureList = { ...featureList }
      this.multiselect = multiselect
      this.menu = true
    },
    getFeatureTitle (feature) {
      if (feature.type === 'PKK') {
        return feature.attrs.id
      }
      if (feature.dComponent === 'layer-poi-feature-card') {
        return feature.name
      }
      return feature.layer.name
    },
    getFeatureSubtitle (feature) {
      if (feature.type === 'PKK') {
        return 'PKK'
      }
      if (feature.dComponent === 'layer-poi-feature-card') {
        return feature.layerName
      }
      return feature.template && feature.template.name
    },
    showFeature (feature) {
      this.$emit('show', { feature, multiselect: this.multiselect })
    }
  }
}
</script>
