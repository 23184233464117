<template>
  <div>
    <div class="simulation-btn-group mb-4 mt-2">
      <v-btn :disabled="!featuresForChange.length" :loading="simulationLoading" icon outlined tile @click="simulate">
        <v-icon>mdi-play</v-icon>
      </v-btn>
      <v-btn :disabled="!selectedFeatures.length" icon outlined tile @click="addFeaturesForSimulation">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn :disabled="!featuresForChange.length" icon outlined tile @click="clearAll">
        <v-icon>mdi-eraser</v-icon>
      </v-btn>
    </div>
    <div v-if="featuresForChange.length">
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
          <th>Режим</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="object in featuresForChange" style="cursor: pointer"
            @click="emitShowFeatureBySys(getFeatureKey(object))">
          <td>{{ getFeatureKey(object) }}</td>
          <td class="py-2">
            <v-select
              v-model="object.mode"
              :items="object.modeList"
              dense
              hide-details
              item-text="title"
              item-value="index"
              outlined
              return-object
              @click.stop
            />
          </td>
          <td>
            <v-btn icon @click.stop="removeFeatureFromSimulation(object)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div v-if="simulationResultFeaturesDto.length">
      <div class="pl-3 py-3 font-weight-bold">
        В результате операции затронуто {{ simulationResultFeaturesDto.length }} элементов.
      </div>
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
          <th>Новое состояние</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="object in simulationResultFeaturesDto" style="cursor: pointer"
            @click="emitShowFeatureBySys(object.id)">
          <td>{{ object.id }}</td>
          <td>{{ object.state }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div>
      <portal to="simulation-layer">
        <vl-feature
          v-for="feature in featuresForChange"
          :properties="{obj: feature}"
        >
          <component
            :is="getComponent(feature.geometry.type)"
            v-if="feature.geometry"
            :coordinates="feature.geometry.coordinates"
          />

          <vl-style-box>
            <vl-style-circle :radius="7">
              <vl-style-fill
                color="yellow"
              />
            </vl-style-circle>
            <vl-style-stroke
              :width="5"
              color="yellow"
            />
          </vl-style-box>
        </vl-feature>

        <vl-feature
          v-for="feature in changedFeatures"
          :properties="{
            obj: feature
          }"
        >
          <component
            :is="getComponent(feature.type)"
            :coordinates="feature.type === 'Point' ? feature.coordinates[0] : feature.coordinates"
          />
          <vl-style-box>
            <vl-style-circle :radius="7">
              <vl-style-fill
                color="purple"
              />
            </vl-style-circle>
            <vl-style-stroke
              :width="5"
              color="purple"
            />
          </vl-style-box>
        </vl-feature>
      </portal>
    </div>
  </div>
</template>

<script>

import zwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'

export default {
  name: "NetworkAnalyzeSwitchBar",
  props: {
    selectedFeatures: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    featuresForChange: [],
    simulationResultFeaturesDto: [],
    changedFeatures: [],
    layer: null,
    simulationLoading: false
  }),
  methods: {
    async simulate () {
      this.simulationLoading = true
      EventBus.$emit('clearSelectedFeatures')
      zwsCommandBuilder.analyzeNetworkSwitch(this.layer.layerId, this.featuresForChange.map(el => ({
        id: this.getFeatureKey(el),
        modeId: el.mode.index
      })))
        .then(res => {
          if (res.length === 0) {
            EventBus.$emit('showInfoMessage', 'Анализ переключений вернул пустой результат')
          }
          this.simulationResultFeaturesDto = res
          this.getChangedObjectsWithParams()
        })
        .catch(err => {
          console.error(err.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.simulationLoading = false)
    },
    addFeaturesForSimulation () {
      if (this.selectedFeatures.some(o => o.layer.type !== 'ZWS')) {
        return EventBus.$emit('showInfoMessage', 'Выбраны объекты неподдерживаемого слоя')
      }
      if (!this.layer) {
        this.layer = this.selectedFeatures[0].layer
      }
      this.simulationResultFeaturesDto = []
      this.changedFeatures = []
      const featuresToPush = JSON.parse(JSON.stringify(this.selectedFeatures))
      featuresToPush.forEach(f =>
        this.featuresForChange.some(el => this.getFeatureKey(el) === this.getFeatureKey(f) || el.layer.id !== this.layer.id)
          ? null
          : this.featuresForChange.push(f)
      )
    },
    getFeatureKey (feature) {
      return feature.props.find(el => el.isKey).value
    },
    clearAll () {
      this.featuresForChange = []
      this.simulationResultFeaturesDto = []
      this.changedFeatures = []
      this.layer = null
    },
    getChangedObjectsWithParams () {
      const ids = this.simulationResultFeaturesDto
        .filter(el => !this.featuresForChange.some(o => +this.getFeatureKey(o) === +el.id))
        .map(it => it.id)
        .join(',')
      zwsCommandBuilder.getElemsByID(this.layer, ids, null, true,true)
        .then(data => this.changedFeatures = data)
    },
    emitShowFeatureBySys (sys) {
      EventBus.$emit('showFeature', this.layer, sys)
    },
    getComponent (geometryType) {
      switch (geometryType) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        case 'Polygon' :
          return 'vl-geom-polygon'
        case 'MultiPolygon' :
          return 'vl-geom-multi-polygon'
        default:
          console.error('Unsupported geometry type: ' + geometryType)
      }
    },
    removeFeatureFromSimulation (object) {
      this.featuresForChange = this.featuresForChange.filter(el => this.getFeatureKey(object) !== this.getFeatureKey(el))
      this.simulationResultFeaturesDto = []
      this.changedFeatures = []
    }
  }
}
</script>
