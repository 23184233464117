<template>
  <div class="pl-2 pr-3">
    <div v-if="selectedSimulationType == null">
      <span class="subtitle-1 font-weight-bold">Выберите тип симуляции</span>
      <v-list>
        <v-list-item v-for="type in simulationTypes" @click="selectedSimulationType = type">
          <v-icon class="mr-3">{{ type.icon }}</v-icon>
          <span>
            {{ type.title }}
          </span>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="selectedSimulationType">
      <div>
        <v-btn icon @click="selectedSimulationType = null">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span>
          {{ selectedSimulationType.title }}
        </span>
      </div>
      <component
        :is="getSimulationComponent()"
        :selected-features="selectedFeatures"
        :simulationType="selectedSimulationType.value"
      />
    </div>
  </div>
</template>

<script>
import NetworkAnalyzeSwitchBar from '@/components/map/zws/simulations/NetworkAnalyzeSwitchBar.vue'
import NetworkRecalcBar from '@/components/map/zws/simulations/NetworkRecalcBar.vue'
import LayerFindSimulationBar from '@/components/map/zws/simulations/LayerFindSimulationBar.vue'

const SIMULATIONS = [
  {
    title: 'Переключения',
    icon: 'mdi-switch',
    value: 'network-analyze-switch'
  },
  {
    title: 'Поиск арматуры',
    icon: 'mdi-paper-roll',
    value: 'network-recalc'
  },
  {
    title: 'Поиск связанных',
    icon: 'mdi-graph',
    value: 'layer-find-connected'
  },
  {
    title: 'Поиск не связанных',
    icon: 'mdi-scatter-plot',
    value: 'layer-find-disconnected'
  },
  {
    title: 'Поиск пути',
    icon: 'mdi-vector-line',
    value: 'layer-find-way'
  },
  {
    title: 'Поиск Колец',
    icon: 'mdi-vector-circle',
    value: 'layer-find-loops'
  },
]

export default {
  name: "MapSimulationBar",
  props: {
    selectedFeatures: Array
  },
  data: () => ({
    simulationTypes: SIMULATIONS,
    selectedSimulationType: null,
  }),
  methods: {
    getSimulationComponent () {
      switch (this.selectedSimulationType.value) {
        case 'network-analyze-switch':
          return NetworkAnalyzeSwitchBar
        case 'network-recalc':
          return NetworkRecalcBar
        case 'layer-find-connected':
        case 'layer-find-disconnected':
        case 'layer-find-way':
        case 'layer-find-loops':
          return LayerFindSimulationBar
      }
    }
  }
}
</script>
