<template>
  <v-card
    class="ma-2 search-result-row"
    outlined
    @click.native="show(item)"
  >
    <v-list-item class="pt-2" style="align-items: start">
      <v-list-item-content class="pt-0">
        <span
          class="subtitle-2 mb-0"
        >
          {{ item.row.dname ? item.row.dname.value : 'Объект' }}
        </span>
        <span v-if="item.row.sys" class="caption">
          #{{ item.row.sys.value }}
        </span>
        <span v-if="item.row.typename" class="font-weight-thin caption">
          {{ item.row.typename.value }}
        </span>
        <span v-if="item.row.modename" class="font-weight-thin caption">
          {{ item.row.modename.value }}
        </span>
      </v-list-item-content>
      <v-list-item-action class="my-0 align-self-start">
        <v-btn :loading="loadingZwsFeatureDialog" icon @click.stop="openZwsFeatureDialog">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card-actions>
      <v-chip label outlined small>
        <v-icon left small>layers</v-icon>
        <span>
          {{ item.layer.name }}
        </span>
      </v-chip>
      <v-spacer/>
      <img :src="item.icon" class="mr-2" width="20"/>
    </v-card-actions>
    <zws-feature-dialog
      ref="zwsFeatureDialog"
    />
  </v-card>
</template>

<script>
import { EventBus } from '@/event-bus'
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge.vue'
import ZwsFeatureDialog from '@/components/map/zws/ZwsFeatureDialog.vue'
import ZwsCommandBuilder from '@/services/zws-command-builder'

export default {
  name: 'ZwsSearchResult',
  components: { ZwsFeatureDialog, LayerPoiBadge },
  data: () => ({
    loadingZwsFeatureDialog: false
  }),
  props: { item: Object },
  methods: {
    show () {
      EventBus.$emit('showFeature', this.item.layer, this.item.row.sys.value)
    },
    async openZwsFeatureDialog () {
      this.loadingZwsFeatureDialog = true
      ZwsCommandBuilder.getElemsByID(this.item.layer, this.item.row.sys.value)
        .then(feature => this.$refs.zwsFeatureDialog.open(feature))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.loadingZwsFeatureDialog = false)
    }
  }
}
</script>
