<template>
  <view-data-page
    v-if="data"
    ref="viewDataPage"
    :data="data"
    :dialog-mode="dialogMode"
    :selectedView="selectedView"
    :split-screen-mode="splitScreenMode"
    :tab-list="tabList"
    @selectView="selectView"
  >

    <template v-slot:tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="$refs.map.refresh()" v-on="on">
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('refresh') }}</span>
      </v-tooltip>
    </template>

    <template
      v-if="setAbility('MODERATOR')"
      v-slot:actions
    >
      <v-list-item
        v-if="setAbility('MODERATOR')"
        dense
        @click="save"
      >
        <v-list-item-avatar>
          <v-icon>save</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('button.save') }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="setAbility('MODERATOR')"
        dense
        @click="saveAs(false)"
      >
        <v-list-item-avatar>
          <v-icon>save_alt</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('button.saveAs') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="setAbility('ADMIN') && !data.parentMap"
        dense
        @click="saveAs(true)"
      >
        <v-list-item-avatar>
          <v-icon>crop</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          Создать представление (наследование)
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="setAbility('ADMIN')"
        dense
        @click="$refs.map.runImport()"
      >
        <v-list-item-avatar>
          <v-icon>import_export</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('importGeoJSON') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        dense
        @click="$refs.viewDataPage.showInfo()"
      >
        <v-list-item-avatar>
          <v-icon>info</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('info') }}
        </v-list-item-content>
      </v-list-item>
    </template>

    <template slot="doc">
      <d-map
        v-if="data && gisCredentials"
        ref="map"
        :map="data"
        :poi="poi"
        :source-layers="layers"
        @closeSearchLpDialog="closeSearchLpDialog"
        @openLayersDialog="openLayersDialog"
        @openSearchLpDialog="openSearchLpDialog"
      />
      <layers-dialog-mobile
        ref="layersDialogMobile"
        :data="data"
        :layers.sync="layers"
        :search-field="searchField"
        class="mt-2"
        @save="save"
        @saveAs="saveAs"
        @refresh-map="$refs.map.refresh()">
        <template v-slot:searchLayers>
          <v-text-field
            v-model="searchField"
            :flat="!solo"
            :placeholder="$t('layers')"
            :solo="solo"
            :solo-inverted="!solo"
            class="mx-2 mb-2"
            dense
            hide-details
            @focusin="solo=true"
            @focusout="solo=false">
            <template v-slot:append>
              <v-btn icon small @click="openSearchLayerDialog">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </template>
      </layers-dialog-mobile>
      <search-lp-dialog-mobile ref="searchLpDialogMobile">
        <template v-slot:search>
          <div>
            <portal-target name="map-search"/>
          </div>
        </template>
      </search-lp-dialog-mobile>
      <search-layer-dialog ref="searchLayerDialog" @select="addLayer"/>
    </template>

    <template slot="navigation">
      <div v-show="selectedView === 'layers'">
        <v-text-field
          v-model="searchField"
          :flat="!solo"
          :placeholder="$t('layers')"
          :solo="solo"
          :solo-inverted="!solo"
          class="mx-2"
          dense
          hide-details
          @focusin="solo=true"
          @focusout="solo=false">
          <template v-slot:append>
            <v-btn icon small @click="openSearchLayerDialog">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <map-config
          :data="data"
          :layers.sync="layers"
          :search-field="searchField"
          class="mt-2"
          @deleteLayer="deleteLayer"
          @save="save"
          @saveAs="saveAs"
          @refresh-map="$refs.map.refresh()"
        />

        <v-card
          v-if="data.parentMap"
          class="caption ma-2 pa-2"
          outlined
        >
          Эта карта является проекцией от карты <b>{{ data.parentMap.name }}</b>.
          <br/>
          Список слоев, настройки (темы, надписи) и порядок отображения настраиваются в родительской карте.
        </v-card>
      </div>

      <portal-target v-if="selectedView==='legend'" name="map-legend"/>

      <portal-target v-if="selectedView==='simulation'" name="map-simulation-bar"/>

      <create-map-dialog ref="createMapDialog"/>
    </template>
  </view-data-page>
</template>

<script>
import ViewDataPage from '../utils/ViewDataPage'
import SearchLayerDialog from '@/components/map/helpers/SearchLayerDialog'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/map-view/messages'
import CreateMapDialog from '@/components/map/CreateMapDialog'
import { mapGetters } from 'vuex'
import DMap from '@/components/map/DMap'
import LayersDialogMobile from '@/components/map/LayersDialogMobile.vue'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import SearchLpDialogMobile from '@/components/map/SearchLpDialogMobile.vue'
import RelationsData from '@/components/utils/RelationsData.vue'

export default {
  name: 'MapView',
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dialogMode: {
      type: Boolean,
      default: false
    },
    dataId: Number,
    poiProp: {
      type: Object,
      default: null
    }
  },
  mixins: [displayServiceMixin],
  components: {
    RelationsData,
    SearchLpDialogMobile,
    LayersDialogMobile,
    DMap,
    CreateMapDialog,
    SearchLayerDialog,
    MapConfig: () => import('@/components/map/helpers/MapConfig'),
    ViewDataPage
  },
  data () {
    return {
      poi: this.poiProp,
      solo: false,
      searchField: '',
      selectedView: 'layers',
      tabList: [
        {
          icon: 'layers',
          view: 'layers',
          tooltipText: this.$t('layers')
        },
        {
          icon: 'mdi-map-legend',
          view: 'legend',
          tooltipText: this.$t('legend')
        },
        {
          icon: 'message',
          view: 'comments',
          tooltipText: this.$t('comments')
        },
        {
          icon: 'mdi-sitemap',
          view: 'simulation',
          tooltipText: this.$t('simulations')
        }
      ],
      data: {},
      layers: []
    }
  },
  i18n: { messages },
  mounted () {
    EventBus.$on('closeLpBottomSheet', this.returnToSearchLpDialog)
  },
  methods: {
    async getMapById (id) {
      let response = await this.$axios.get('data/get', { params: { id: id } })
      let map = response.data
      map.position = map.position ? JSON.parse(map.position) : null
      return map
    },
    async init () {
      this.data = null
      let id = this.dataId || this.$route.params.id
      let map = await this.getMapById(id)
      let layerConfigList
      if (map.parentMap) {
        layerConfigList = map.parentMap.layerConfigList
      } else {
        layerConfigList = map.layerConfigList
      }
      this.layers = layerConfigList
        .filter(it => it.layer.isActive)
        .sort((a, b) => {
          return a.indexNumber > b.indexNumber ? 1 : -1
        })
        .map(it => {
          let layer = it.layer
          layer.visible = it.visible
          return layer
        })
      let { sys, layerId } = this.$route.query

      if (sys && layerId) {
        let layer = this.layers.find(it => it.id === Number(layerId))
        if (layer) this.poi = { layer: layer, sys: sys }
      }
      this.data = map
    },
    selectView (view) {
      this.selectedView = view
    },
    addLayer (layer) {
      this.layers.push(layer)
      this.$refs.map.layers.push(layer)
    },
    deleteLayer (layer) {
      this.$refs.map.layers = this.$refs.map.layers.filter(l => l.id !== layer.id)
    },
    saveAs (inheritance = false) {
      let map = { ...this.data }
      if (inheritance) {
        map.parentMap = { id: this.data.id }
        map.layerConfigList = []
      } else {
        map.parentMap = null
      }
      map.id = 0
      map.name = ''
      this.prepareToSave(map)
      this.$refs.createMapDialog.create(map)
    },
    prepareToSave (map, withPosition = true) {
      map.layerConfigList = this.layers
        .map((item, index) => ({
          layer: { id: item.id },
          indexNumber: index,
          visible: item.visible,
          labels: item.labels ? item.labels.filter(it => it.isActive).map(it => it.id).join() : '',
          themes: item.themes ? item.themes.filter(it => it.isActive).map(it => it.id).join() : ''
        }))

      if (withPosition) {
        map.position = this.$refs.map.getPosition()
      } else {
        let newPosition = this.$refs.map.getPosition()
        map.position.center = newPosition.center
        map.position.zoom = newPosition.zoom
        map.position = JSON.stringify(map.position)
      }
    },
    async save () {
      let map = await this.getMapById(this.data.id)
      this.prepareToSave(map, false)
      this.$axios
        .post('/map/save', map)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('mapSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.loading = false)
    },
    openSearchLpDialog () {
      if (this.displayXS) {
        this.$refs.searchLpDialogMobile.open()
      }
    },
    closeSearchLpDialog () {
      this.$refs.searchLpDialogMobile.close()
    },
    openSearchLayerDialog () {
      this.$refs.searchLayerDialog.open()
    },
    openLayersDialog () {
      this.$refs.layersDialogMobile.open()
    },
    returnToSearchLpDialog () {
      if (this.displayXS && this.$store.state.returnToSearchLpDialog) {
        this.openSearchLpDialog()
      }
      this.$store.dispatch('setReturnToSearchLpDialog', false)
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters([
      'gisCredentials'
    ])
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
