<template>
  <div>
    <div class="mb-4 mt-2">
      <div class="simulation-btn-group">
        <v-btn :disabled="!featureForAnalyze" :loading="simulationLoading" icon outlined tile @click="simulate">
          <v-icon>mdi-play</v-icon>
        </v-btn>
        <v-btn :disabled="!selectedFeatures.length" icon outlined tile @click="addFeatureForSimulation">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn :disabled="!featureForAnalyze" icon outlined tile @click="clearAll">
          <v-icon>mdi-eraser</v-icon>
        </v-btn>
      </div>
      <v-checkbox
        v-model="isolation"
        dense
        class="mt-2"
        hide-details
        label="Полная изоляция"
      />
    </div>
    <div v-if="featureForAnalyze">
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
        </tr>
        </thead>
        <tbody>
        <tr style="cursor: pointer" @click="emitShowFeatureBySys(getFeatureKey(featureForAnalyze))">
          <td>{{ getFeatureKey(featureForAnalyze) }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div v-if="analyzedFeaturesIds.length">
      <div class="pl-3 py-3 font-weight-bold">
        В результате операции затронуто {{ analyzedFeaturesIds.length }} элементов.
      </div>
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="id in analyzedFeaturesIds"
          style="cursor: pointer"
          @click="emitShowFeatureBySys(id)"
        >
          <td>{{ id }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div>
      <portal to="simulation-layer">
        <vl-feature
          v-if="featureForAnalyze"
          :properties="{
          obj: featureForAnalyze
        }"
        >
          <component
            :is="getComponent(featureForAnalyze.geometry.type)"
            v-if="featureForAnalyze.geometry"
            :coordinates="featureForAnalyze.geometry.coordinates"
          />

          <vl-style-box>
            <vl-style-circle :radius="7">
              <vl-style-fill
                color="yellow"
              />
            </vl-style-circle>
            <vl-style-stroke
              :width="5"
              color="yellow"
            />
          </vl-style-box>
        </vl-feature>

        <vl-feature
          v-for="feature in changedFeatures"
          :properties="{
            obj: feature
          }"
        >
          <component
            :is="getComponent(feature.type)"
            :coordinates="feature.type === 'Point' ? feature.coordinates[0] : feature.coordinates"
          />
          <vl-style-box>
            <vl-style-circle :radius="7">
              <vl-style-fill
                color="purple"
              />
            </vl-style-circle>
            <vl-style-stroke
              :width="5"
              color="purple"
            />
          </vl-style-box>
        </vl-feature>
      </portal>
    </div>
  </div>
</template>

<script>

import zwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'

export default {
  name: "NetworkRecalcBar",
  props: {
    selectedFeatures: Array
  },
  data: () => ({
    featureForAnalyze: null,
    analyzedFeaturesIds: [],
    changedFeatures: [],
    layer: null,
    simulationLoading: false,
    isolation: false
  }),
  methods: {
    async simulate () {
      this.simulationLoading = true
      EventBus.$emit('clearSelectedFeatures')
      zwsCommandBuilder.analyzeNetworkRecalc(this.layer.layerId, this.getFeatureKey(this.featureForAnalyze), this.isolation)
        .then(res => {
          if (res.length === 0) {
            EventBus.$emit('showInfoMessage', 'Анализ переключений вернул пустой результат')
          }
          this.analyzedFeaturesIds = res
          this.getChangedObjectsWithParams()
        })
        .catch(err => {
          console.error(err.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.simulationLoading = false)
    },
    addFeatureForSimulation () {
      if (this.selectedFeatures[0].layer.type !== 'ZWS') {
        return EventBus.$emit('showInfoMessage', 'Выбраны объекты неподдерживаемого слоя')
      }
      this.analyzedFeaturesIds = []
      this.changedFeatures = []
      this.featureForAnalyze = JSON.parse(JSON.stringify(this.selectedFeatures[0]))
      this.layer = this.featureForAnalyze.layer
    },
    getFeatureKey (feature) {
      return feature.props.find(el => el.isKey).value
    },
    clearAll () {
      this.featureForAnalyze = null
      this.analyzedFeaturesIds = []
      this.changedFeatures = []
      this.layer = null
    },
    getChangedObjectsWithParams () {
      zwsCommandBuilder.getElemsByID(this.layer, this.analyzedFeaturesIds.join(','), null, true, true)
        .then(data => this.changedFeatures = data)
    },
    emitShowFeatureBySys (sys) {
      EventBus.$emit('showFeature', this.layer, sys)
    },
    getComponent (geometryType) {
      switch (geometryType) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        case 'Polygon' :
          return 'vl-geom-polygon'
        case 'MultiPolygon' :
          return 'vl-geom-multi-polygon'
        default:
          console.error('Unsupported geometry type: ' + geometryType)
      }
    }
  }
}
</script>
