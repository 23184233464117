<template>
  <div>
    <vl-layer-vector v-if="!editMode" :z-index="10">
      <vl-source-vector ref="vlSourceVector">
        <vl-style-box>
          <vl-style-circle :radius="10">
            <vl-style-stroke
              :color="pulse ? 'blue' : 'red'"
              :width="6"
            />
          </vl-style-circle>

          <vl-style-stroke
            :color="pulse ? 'blue' : 'red'"
            :width="5"
          />
        </vl-style-box>
      </vl-source-vector>

      <vl-feature
        v-for="feature in selected"
        :properties="{
          obj: feature
        }"
      >
        <component
          :is="getComponent(feature)"
          v-if="feature.geometry"
          :coordinates="feature.geometry.coordinates"
        />

        <vl-style-box>
          <vl-style-circle :radius="smallPoint ? 1 : 10">
            <vl-style-stroke
              :color="pulse ? 'blue' : 'red'"
              :width="6"
            />
          </vl-style-circle>

          <vl-style-stroke
            :color="pulse ? 'blue' : 'red'"
            :width="5"
          />
        </vl-style-box>
      </vl-feature>
    </vl-layer-vector>

    <div v-else>
      <vl-layer-vector :z-index="200">
        <vl-source-vector
          ref="editorVector"
          :features.sync="editableFeatures"
          ident="draw-target"
        />
        <measure-style-box/>
      </vl-layer-vector>

      <vl-interaction-modify source="draw-target"/>
    </div>

    <selected-feature-card
      v-if="!widget && selected.length === 1"
      ref="selectedFeatureCard"
      :features="selected"
      @close="close"
      @edit="edit"
      @save="save"
      @stopEdit="stopEdit"
    />

    <v-btn
      v-if="selected.length > 1"
      absolute
      bottom
      class="ma-2"
      right style="z-index: 100" @click="$emit('showFeatures')"
    >
      <v-icon left>search</v-icon>
      {{ $t('searchObjects') }}
    </v-btn>

  </div>
</template>

<script>
import SelectedFeatureCard from '@/components/map/SelectedFeatureCard'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/selected-feature/messages'
import MeasureStyleBox from '@/components/map/helpers/measurer/MeasureStyleBox'

export default {
  name: 'SelectedFeature',
  components: { MeasureStyleBox, SelectedFeatureCard },
  i18n: { messages },
  props: {
    selected: Array,
    widget: Boolean,
    state: {
      type: Object,
      default: () => ({ editMode: false })
    },
    smallPoint: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pulse: false,
    editableFeatures: []
  }),
  created () {
    this.makePulse(false)
  },
  methods: {
    async save () {
      if (this.editMode && this.editableFeatures && this.editableFeatures[0] && this.editableFeatures[0].geometry) {
        this.$emit('update:selected', [{ ...this.selected[0], geometry: this.editableFeatures[0].geometry }])
      }
      this.$nextTick(() => {
        this.$refs.selectedFeatureCard.save()
          .then(() => {
            EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
            this.close()
            this.$emit('refresh', this.selected[0].layer.id)
          })
          .catch(e => {
            console.error(e)
            EventBus.$emit('showErrorMessage', this.$t('error'))
          })
      })
    },
    show (feature) {
      this.$refs.vlSourceVector.addFeature(feature)
      this.feature = feature
    },
    makePulse (pulse) {
      this.pulse = pulse
      setTimeout(() => {
          this.makePulse(!this.pulse)
        },
        600)
    },
    getComponent (feature) {
      switch (feature.geometry.type) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        case 'Polygon' :
          return 'vl-geom-polygon'
        case 'MultiPolygon' :
          return 'vl-geom-multi-polygon'
        default:
          console.error('Unsupported geometry type: ' + feature.geometry.type)
      }
    },
    close () {
      this.state.editMode = false
      this.$emit('update:selected', [])
    },
    edit () {
      this.state.editMode = !this.state.editMode
      if (this.editMode) {
        const feature = this.selected[0]
        if (feature.dComponent === 'geo-server-feature-card') {
          setTimeout(() => {
            this.$refs.editorVector.addFeature(feature)
          }, 200)
        } else {
          setTimeout(() => {
            this.editableFeatures.push({
              type: 'Feature',
              geometry: feature.geometry,
              properties: {}
            })
          }, 200)
        }
      } else {
        this.editableFeatures = []
      }
    },
    stopEdit () {
      this.state.editMode = false
      this.editableFeatures = []
    }
  },
  computed: {
    editMode () {
      return this.state.editMode
    }
  }
}
</script>
